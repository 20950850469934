<template>
  <div class="info-cards">
    <div
      v-for="(item, i) in infoCardData"
      :key="i"
      class="info-cards__card"
      :class="`info-cards__card--${size}`"
    >
      <p class="info-cards__card__title">
        {{ item.title }}
      </p>
      <p v-if="item.text" class="info-cards__card__text">
        {{ item.text }}
      </p>
      <span v-if="item.img" class="info-cards__card__img">
        <slot name="logo" class="info-cards__card__img" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoCard',
  props: {
    infoCardData: {
      type: Array,
      required: true
    },
    size: {
      type: String,
      default: 'sm'
    }
  }
}
</script>

<style lang="scss" scoped>
.info-cards {
  display: flex;
  flex-wrap: wrap;

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f8f8;

    &--lg {
      height: 102px;

      width: 252px;

      @media (min-width: $tablet-min) {
        width: 337px;
        height: 102px;
      }

      @media (min-width: $tablet-max) {
        height: 102px;
        width: 252px;
      }
    }

    &--md {
      height: 77px;
      width: 252px;

      @media (min-width: $tablet-min) {
        width: 337px;
        height: 77px;
      }

      @media (min-width: $tablet-max) {
        height: 77px;
        width: 252px;
      }
    }

    &--sm {
      height: 75px;
      width: 122px;

      @media (min-width: $tablet-min) {
        width: 163px;
        height: 100px;
      }

      @media (min-width: $tablet-max) {
        height: 75px;
        width: 122px;
      }

      & .info-cards__card__title {
        @media (min-width: $tablet-min) {
          margin-top: 12px;
          margin-bottom: 8px;
        }

        @media (min-width: $tablet-max) {
          margin-top: 6px;
          margin-bottom: 0;
        }
      }

      & .info-cards__card__text {
        @media (min-width: $tablet-min) {
          max-width: unset;
        }

        @media (min-width: $tablet-max) {
          max-width: 100px;
        }
      }
    }

    &__title,
    &__text {
      margin: 0;
    }

    &__title {
      color: #235262;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 25px;
      margin-top: 6px;
    }

    &__text {
      color: #545454;
      font-size: 12px;
      line-height: 14px;
      max-width: 100px;
    }

    &__img {
      margin-top: 5px;
    }
  }
}
</style>
