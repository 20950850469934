<template>
  <div class="logo-card">
    <h1 class="logo-card__title">
      {{ title }}
    </h1>
    <div class="logo-card__logo">
      <a v-if="linkString" :href="linkString" :target="targetType">
        <slot name="logo" />
      </a>
      <slot v-else name="logo" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogoCard',
  props: {
    title: {
      type: String,
      default: 'Welcome from'
    },
    linkString: {
      type: String,
      default: ''
    },
    targetType: {
      type: String,
      default: '_blank'
    }
  }
}
</script>

<style lang="scss" scoped>
  .logo-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 107px;
    width: 252px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.07);

      &__title {
      color: #545454;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }

    &__logo {
      width: 151.2px;
    }
  }
</style>
