<template>
  <div>
    <div
      ref="trustbox"
      class="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="5419b732fbfb950b10de65e5"
      data-businessunit-id="5989753e0000ff0005a86115"
      data-style-height="130px"
      data-style-width="100%"
      data-theme="light"
      data-stars="5"
    >
      <a href="https://uk.trustpilot.com/review/www.thinkbusinessloans.com" target="_blank" rel="noopener">Trustpilot</a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TrustPilot',
  mounted () {
    const trustbox = this.$refs.trustbox
    window.Trustpilot.loadFromElement(trustbox)
  }
}
</script>
