<template>
  <div :class="`hero-split ${getPartner.name}`">
    <div class="container">
      <div class="hero-split__container">
        <!-- main money input side -->
        <div class="hero-split__main">
          <div class="hero-split__section">
            <!-- logo -->
            <a href="https://thinkbusinessloans.com/" class="hero-split__main__logo animation-cascade__item">
              <SvgThinkNew class="svg-think-new" />
              <!-- <SvgThink v-else /> -->
            </a>
            <!-- small tittle -->
            <p class="hero-split__small-title animation-cascade__item">
              <!-- Great, let’s fine-tune your loan options -->
              {{ splitComponentContent.title }}
              <span class="hero-split__small-title__break">{{
                splitComponentContent.titleLineBreak
              }}</span>
            </p>
            <!-- loan input and question -->
            <LoanInputHome
              :button-text="'Next'"
              class="hero-split__loan-input animation-cascade__item"
              :question-text="splitComponentContent.loanInputQuestion"
              :error-input-border="true"
              :delay-next="isDelayNext"
              @next="next"
            />
          </div>
        </div>
        <!-- side -->
        <div class="hero-split__side animation-slide-in">
          <div class="hero-split__side__container">
            <!-- title and logo -->
            <LogoCard
              :link-string="getPartner.link"
              class="hero-split__side__logo-card"
              :class="`hero-split__side__logo-card--${splitComponentContent.partnerName}`"
            >
              <template #logo>
                <!-- <SvgBionic v-if="isBionic" />
                <SvgMoney v-if="isMoney" />
                <SvgUswitch v-if="isUsw" /> -->

                <component
                  :is="componentSvgOptions[splitComponentContent.partnerName]"
                />
              </template>
            </LogoCard>
            <!-- 4 cards -->
            <InfoCard
              v-if="isMoney || isForbes"
              class="hero-split__side__info-card"
              :info-card-data="infoCardData"
            />
            <!-- one card - logos -->
            <InfoCard
              v-if="isUsw || isBionic || isMSM"
              class="
                hero-split__side__info-card hero-split__side__info-card--md
              "
              :info-card-data="trustedCardData"
              :size="'lg'"
            >
              <template #logo>
                <SvgBankGroup />
              </template>
            </InfoCard>
            <!-- one card - content -->
            <InfoCard
              v-if="isUsw || isBionic"
              class="
                hero-split__side__info-card hero-split__side__info-card--md
              "
              :info-card-data="contentCardData"
              :size="'md'"
            />
            <!-- small intro -->
            <p class="hero-split__side__intro">
              Think Business Loans has secured more than £450 million in funding
              for over 5,000 UK businesses.
            </p>
            <!-- trust pilot -->
            <TrustPilot class="hero-split__side__trust-pilot" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import InfoCard from '@/components/shared/InfoCard'
import LogoCard from '@/components/shared/LogoCard'
import SvgThinkNew from '@/components/shared/svg/SvgThinkNew'
import SvgThink from '@/components/shared/svg/SvgThink'
import SvgMoney from '@/components/shared/svg/SvgMoney'
import SvgUswitch from '@/components/shared/svg/SvgUswitch'
import SvgForbes from '@/components/shared/svg/SvgForbes'
import SvgMSM from '@/components/shared/svg/SvgMSM'
import SvgBankGroup from '@/components/shared/svg/SvgBankGroup'
import SvgBionic from '@/components/shared/svg/SvgBionic'
import LoanInputHome from '@/components/home/LoanInputHome'
import TrustPilot from '@/components/results/TrustPilot'
import { mapGetters } from 'vuex'
import { MONEY, USW, BIONIC, FORBES, MSM } from '@/helpers/constants/partner-names'

export default {
  name: 'HomepageHeroSplit',
  components: {
    SvgThink,
    SvgThinkNew,
    SvgBankGroup,
    InfoCard,
    LoanInputHome,
    LogoCard,
    TrustPilot
  },
  props: {
    splitComponentContent: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      windowWidth: 0,
      count: 0,
      BIONIC,
      MONEY,
      USW,
      FORBES,
      infoCardData: [
        { title: 'Loan type', text: 'Unsecured term loan' },
        { title: 'Loan amount', text: '£25,000 to £5,000,000' },
        { title: 'Representative APR', text: '6.36% APR' },
        { title: 'Loan term', text: '3 months to 72 months' }
      ],
      trustedCardData: [{ title: 'Over 100 trusted lenders', img: true }],
      contentCardData: [
        {
          title: 'Multiple finance products',
          text: 'Including the government backed Recovery Loan Scheme (RLS)'
        }
      ],
      componentSvgOptions: {
        bionic: SvgBionic,
        money: SvgMoney,
        usw: SvgUswitch,
        forbes: SvgForbes,
        moneysupermarket: SvgMSM
      }
    }
  },
  computed: {
    ...mapGetters(['getPartner']),
    isDelayNext () {
      if (this.windowWidth > 940) return true
      return false
    },
    isBionic () {
      return this.splitComponentContent.partnerName === BIONIC
    },
    isMoney () {
      return this.splitComponentContent.partnerName === MONEY
    },
    isUsw () {
      return this.splitComponentContent.partnerName === USW
    },
    isForbes () {
      return this.splitComponentContent.partnerName === FORBES
    },
    isMSM () {
      return this.splitComponentContent.partnerName === MSM
    }
  },
  created () {
    window.addEventListener(
      'resize',
      debounce((e) => {
        this.windowResize(e)
      }, 200)
    )
  },
  mounted () {
    this.windowWidth = window.outerWidth
    this.addClassName('.hero-split__main', 'animation-on-load-cascade')
    this.addClassName('.hero-split__container', 'animation-slide-up')
  },
  destroyed () {
    window.removeEventListener('resize', this.windowResize)
  },
  methods: {
    next () {
      // slide out animation before page change
      this.removeClassName('.hero-split__side', 'animation-slide-in')
      this.addClassName('.hero-split__side', 'animation-slide-out')
      this.addClassName('.hero-split__main', 'fade-out')
    },
    windowResize (e) {
      this.count = 1
      this.windowWidth = e.target.outerWidth
      if (this.count === 1) {
        this.removeClassName('.hero-split__main', 'animation-on-load-cascade')
        this.removeClassName('.hero-split__container', 'animation-slide-up')
      }
    },
    addClassName (elm, className) {
      document.querySelector(elm).classList.add(className)
    },
    removeClassName (elm, className) {
      if (document.querySelector(elm)) {
        document.querySelector(elm).classList.remove(className)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-think-new {
  height: 62px;

  @media (max-width: $tabletSize) {
    width: 120px;
  }
}

.container {
  @media (max-width: $tabletSize - 1) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: 100% !important;
  }

  @media (min-width: 576px) {
    max-width: 746px !important;
  }

  @media (min-width: 768px) {
    max-width: 1000px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1200px !important;
  }
}
.hero-split {
  position: relative;

  &__section {
    @media (min-width: $tablet-min) {
      max-width: 348px;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: $tablet-max) {
      max-width: unset;
      margin-left: unset;
      margin-right: unset;
    }
  }

  &__container {
    display: flex;
    position: relative;
    z-index: 2;
    flex-direction: column;

    @media (min-width: $tabletSize) {
      flex-direction: row;
    }
  }

  &__small-title {
    margin: 0;
    text-align: left;
    font-size: 16px;
    letter-spacing: -0.07px;
    line-height: 24px;
    color: $color-grey-3;
    margin-bottom: 12px;
    margin-top: 20px;
    max-width: 278px;

    @media (min-width: $tablet-min) {
      font-size: 20px;
      line-height: 25px;
      margin-top: 33px;
      margin-bottom: 29px;
      max-width: unset;
    }

    @media (min-width: $tabletSize) {
      margin-top: 66px;
      margin-bottom: 42px;
    }

    &__break {
      @media (min-width: $tabletSize) {
        display: block;
      }
    }

    @media (min-width: $tabletSize) {
      font-size: 24px;
      letter-spacing: -0.1px;
      line-height: 30px;
    }
  }

  &__main,
  &__side {
    @media (min-width: $tabletSize) {
      padding-top: 92px;
      height: 99vh;
    }

    @media (min-width: 990px) {
      height: 100vh;
    }
  }

  .animation-slide-up .hero-split__main {
    /* slide up animation */
    transform: translateY(50px);
    animation: slide $slideTime linear forwards;

    @-webkit-keyframes slide {
      100% {
        transform: translateY(-0px);
      }
    }

    @keyframes slide {
      100% {
        transform: translateY(0px);
      }
    }
  }

  &__main {
    transition: opacity $slideTime ease-out;
    @media (max-width: $tabletSize - 1) {
      padding: 15px 15px 120px 15px;
    }

    &.fade-out {
      opacity: 0;
    }
    @media (min-width: $tabletSize) {
      width: 55%;
      padding-right: 60px;
      width: 70%;
      padding-top: 122px;
    }

    // tablet screen only
    @media screen and (min-width: 700px) and (max-width: 939px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__logo {
      display: block;
      margin-right: auto;
      width: 118px;

      @media (min-width: $tabletSize) {
        width: 178px;
      }
    }
  }

  &__side {
    background-color: #ffffff;
    box-shadow: -3px 2px 4px 0px #ececec;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    &.animation-slide-in {
      /* slide animation */
      @include slideAnimation;

      @media (min-width: $tabletSize) {
        /* for animation */
        @include slideInKeyFrames;
        transform: translateX(290%);
      }

      &:after {
        width: 250%;
      }
    }

    &.animation-slide-out {
      @include slideOutAnimation;
      /* slide out animation non mobile */
      @media (min-width: $tabletSize) {
        &:after {
          box-shadow: none !important;
        }
        @include slideOutKeyFrames;
      }
      /* slide out animation non mobile */
      @media (min-width: 1600px) {
        &:after {
          box-shadow: none !important;
        }
        @include slideOutKeyFramesLargeScreen;
      }
    }

    @media (max-width: $tabletSize - 1) {
      /* slide up animation mobile */
      @include slideUpKeyFrames;
      transform: translateY(50px);
      height: 62vh;
    }

    @media (max-width: 415px) {
      height: 80vh;
    }

    @media (max-width: 320px) {
      height: 92vh;
    }

    @media (min-width: $tabletSize) {
      width: 45%;
      border-top-right-radius: 0;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    @media (min-width: $tabletSize) {
      width: 30%;
    }

    &__logo-card {
      margin-top: 32px;

      @media (min-width: $tablet-min) {
        justify-content: space-around;
        width: 337px;
        height: 90px;
        padding-left: 35px;
        padding-right: 35px;
      }

      @media (min-width: $tablet-max) {
        margin-bottom: 17px;
        justify-content: center;
        height: 107px;
        width: 252px;
        padding-left: 0px;
        padding-right: 0px;
      }

      &--bionic svg {
        width: 90px;
        height: 31px;

        @media (min-width: $tabletSize) {
          width: 118px;
          height: 31px;
        }
      }
    }

    &__intro {
      color: #000000;
      font-size: 14px;
      letter-spacing: -0.06px;
      line-height: 17px;
      text-align: left;
      margin: 24px 0 34px 0;
      max-width: 254px;

      @media (min-width: $tablet-min) {
        max-width: 336px;
      }

      @media (min-width: $tablet-min) {
        max-width: auto;
      }

      @media (min-width: $tabletSize) {
        margin: 40px 0 45px 0;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: $tabletSize) {
        padding-left: 21px;
        align-items: flex-start;
      }

      @media (min-width: 996px) {
        padding-left: 31px;
      }

      @media (min-width: 1200px) {
        padding-left: 81px;
      }
    }

    &::after {
      @media (min-width: $tabletSize) {
        box-shadow: -3px 2px 4px 0px #ececec;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: rgb(255, 255, 255);
        content: "";
        margin: 0 auto;
        position: absolute;
        top: 2px;
        right: -150%;
        bottom: 0;
        left: 0;
        width: 110%;
        z-index: -1;
      }
    }
  }
}
</style>

<style lang="scss">
.hero-split__side__logo-card--usw {
  .logo-card__logo {
    width: 130px;
  }
}
// cascade animation
@media (max-width: $tabletSize - 1) {
  .animation-on-load-cascade {
    .animation-cascade {
      &__item {
        @include fadeInUpAnimation;
      }
      &__item:nth-child(1),
      &__item:nth-child(2),
      &__item:nth-child(3) {
        /* inside LoanInputHome */
        .amount-form__title,
        .amount-form__form {
          @include fadeInUpAnimation;
          animation-delay: $casecadeDelay;
        }
      }
    }
  }

  @include fadeInUpShortKeyFrames;
}
// cascade animation end
/* Global styles into components */
.hero-split {
  iframe {
    height: 57px !important;
  }

  &__side {
    &__logo-card {
      margin-top: 50px;
      margin-bottom: 17px;
      width: 337px;
      height: 90px;

      @media (min-width: $tabletSize - 1) {
        height: 252px;
        width: 107px;
      }

      @media (max-width: $tabletSize - 1) {
        flex-direction: row !important;
      }

      .logo-card__logo {
        @media (max-width: $tabletSize - 1) {
          width: 95px;
        }
      }

      .logo-card__title {
        @media (max-width: $tabletSize - 1) {
          margin: 0;
          margin-right: 5px;
        }
      }
    }

    &__info-card {
      &--md {
        &:nth-of-type(1) {
          margin-bottom: 4px;
        }

        .info-cards__card {
          margin-right: 0 !important;
        }

        .info-cards__card__text {
          max-width: 195px;
          font-size: 10px;
        }
        .info-cards__card__title {
          font-size: 12px;
        }
      }

      @media (max-width: $tabletSize - 1) {
        justify-content: center;
        width: 375px;
      }

      .info-cards__card:nth-of-type(1) {
        margin-right: 8px;
        margin-bottom: 8px;
      }

      .info-cards__card:nth-of-type(3) {
        margin-right: 8px;
      }
    }
  }

  &__loan-input {
    padding-bottom: 0 !important;
    padding-top: 0 !important;

    .amount-form {
      &__title {
        color: #011240 !important;
        margin-bottom: 20px !important;
        font-size: 24px;
        letter-spacing: -0.7px;
        line-height: 30px;
        font-weight: 400;

        @media (min-width: $tablet-min) {
          line-height: 36px;
          font-size: 28px;
          margin-bottom: 29px !important;
        }
      }

      &__form {
        width: 100%;

        .input-container {
          display: flex;
          align-items: center;

          .currency {
            border-right: unset;
          }
        }
      }
    }

    .input-container {
      border-radius: 8px !important;
      max-width: 295px !important;
      border: 1px solid #1370ff;
      box-shadow: none !important;
      height: 64px !important;

      @media (min-width: $tablet-min) {
        height: 72px !important;
        max-width: 348px !important;
      }

      @media (max-width: 500px) {
        max-width: 100% !important;
      }

      @media (min-width: $tabletSize) {
        max-width: 600px !important;
      }

      @media screen and (min-width: 700px) and (max-width: 939px) {
        max-width: unset !important;
      }

      .currency {
        font-size: 22px !important;

        @media (min-width: $tablet-min) {
          font-size: 32px !important;
        }
      }

      .input {
        letter-spacing: 0;
        line-height: 33px;
        font-size: 16px !important;
        color: #000000;

        @media (min-width: $tablet-min) {
          font-size: 24px !important;
        }

        &::placeholder {
          color: #9d9d9d !important;
          font-size: 14px !important;
          @media (min-width: $tablet-min) {
            font-size: 16px !important;
          }

          @media (min-width: $tablet-max) {
            font-size: 20px !important;
          }
        }
      }

      .error {
        left: 0;
        transform: unset;
        bottom: -24px;
        font-size: 14px;
        letter-spacing: -0.06px;
        line-height: 17px;
      }
    }

    .button-partner {
      position: absolute;
      right: 0;
      left: unset;
      transform: translateX(0);
      bottom: -87px;
      min-width: auto;

      @media (min-width: $tablet-min) {
        width: 128px !important;
      }

      @media (min-width: $tabletSize) {
        bottom: -109px;
      }

      .button__right-arrow {
        margin-left: 5px !important;
        display: inline;
      }

      .button-text {
        display: inline-block !important;
      }

      .button {
        width: 96px !important;
        height: 42px;
        margin: 0;

        @media (min-width: $tablet-min) {
          width: 128px !important;
          height: 56px;
        }
        background-color: $blue-2;
      }
    }
  }
}
</style>
